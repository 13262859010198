var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.newUser)?_c('widget',{attrs:{"title":_vm.$tc('phrases.newMaintenanceUser'),"color":"red"}},[_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitNewUserForm.apply(null, arguments)}}},[_c('md-card',[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.name')),
                  }},[_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$tc("words.name"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"type":"text","name":_vm.$tc('words.name'),"id":"name","placeholder":_vm.$tc('words.name')},model:{value:(_vm.maintenanceService.personData.name),callback:function ($$v) {_vm.$set(_vm.maintenanceService.personData, "name", $$v)},expression:"maintenanceService.personData.name"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.name")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.surname')),
                  }},[_c('label',{attrs:{"for":"surname"}},[_vm._v(" "+_vm._s(_vm.$tc("words.surname"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"text","id":"surname","name":_vm.$tc('words.surname'),"placeholder":_vm.$tc('words.surname')},model:{value:(_vm.maintenanceService.personData.surname),callback:function ($$v) {_vm.$set(_vm.maintenanceService.personData, "surname", $$v)},expression:"maintenanceService.personData.surname"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.surname")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.miniGrid')),
                  }},[_c('label',{staticClass:"control-label",attrs:{"for":"mini-grids"}},[_vm._v(" "+_vm._s(_vm.$tc("words.miniGrid"))+" ")]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"id":"mini-grids","name":_vm.$tc('words.miniGrid')},model:{value:(_vm.maintenanceService.personData.mini_grid_id),callback:function ($$v) {_vm.$set(_vm.maintenanceService.personData, "mini_grid_id", $$v)},expression:"maintenanceService.personData.mini_grid_id"}},[_c('md-option',{attrs:{"value":"","selected":"","disabled":""}},[_vm._v(" -- "+_vm._s(_vm.$tc("words.select"))+" -- ")]),_vm._l((_vm.miniGrids),function(miniGrid,index){return _c('md-option',{key:index,attrs:{"value":miniGrid.id}},[_vm._v(" "+_vm._s(miniGrid.name)+" ")])})],2),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.miniGrid")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[[_c('vue-tel-input',{attrs:{"id":"phone","validCharactersOnly":true,"mode":"international","invalidMsg":"invalid phone number","disabledFetchingCountry":false,"disabledFormatting":false,"placeholder":"Enter a phone number","required":true,"preferredCountries":['TZ', 'CM', 'KE', 'NG', 'UG'],"autocomplete":"off","name":_vm.$tc('words.phone'),"enabledCountryCode":"true"},on:{"validate":_vm.validatePhone},model:{value:(_vm.maintenanceService.personData.phone),callback:function ($$v) {_vm.$set(_vm.maintenanceService.personData, "phone", $$v)},expression:"maintenanceService.personData.phone"}}),(!_vm.phone.valid && _vm.firstStepClicked)?_c('span',{staticClass:"md-error",staticStyle:{"color":"red"}},[_vm._v(" invalid phone number ")]):_vm._e()]],2),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.city')),
                  }},[_c('label',[_vm._v(" "+_vm._s(_vm.$tc("phrases.livingIn"))+" ")]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"id":"city","name":_vm.$tc('words.city')},model:{value:(_vm.maintenanceService.personData.city_id),callback:function ($$v) {_vm.$set(_vm.maintenanceService.personData, "city_id", $$v)},expression:"maintenanceService.personData.city_id"}},[_c('md-option',{attrs:{"value":"","selected":"","disabled":""}},[_vm._v(" -- "+_vm._s(_vm.$tc("words.select"))+" -- ")]),_vm._l((_vm.cities),function(city,index){return _c('md-option',{key:index,attrs:{"value":city.id}},[_vm._v(" "+_vm._s(city.name)+" ")])})],2),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.city")))+" ")])],1)],1)]),(_vm.loading)?_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}}):_vm._e()],1),_c('md-card-actions',[_c('md-button',{staticClass:"md-raised",on:{"click":function($event){return _vm.onClose()}}},[_vm._v(" "+_vm._s(_vm.$tc("words.close"))+" ")]),_c('md-button',{staticClass:"md-raised md-primary",attrs:{"disabled":_vm.loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$tc("words.save"))+" ")])],1)],1)],1)])]):_vm._e(),_c('md-dialog',{attrs:{"md-active":_vm.ModalVisibility},on:{"update:mdActive":function($event){_vm.ModalVisibility=$event},"update:md-active":function($event){_vm.ModalVisibility=$event}}},[_c('md-dialog-content',[(_vm.ModalVisibility)?_c('stepper',{attrs:{"purchasingType":'maintenance'}}):_vm._e()],1)],1),_c('redirection-modal',{attrs:{"redirection-url":_vm.redirectionUrl,"imperative-item":_vm.imperativeItem,"dialog-active":_vm.redirectDialogActive}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }