var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('widget',{directives:[{name:"show",rawName:"v-show",value:(_vm.addAgent),expression:"addAgent"}],attrs:{"title":_vm.$tc('phrases.newAgent'),"color":"red"}},[_c('md-card',[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100"},[_c('form',{ref:"agentForm",staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.name')),
                  }},[_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$tc("words.name"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"id":"name","name":_vm.$tc('words.name')},model:{value:(_vm.agentService.agent.name),callback:function ($$v) {_vm.$set(_vm.agentService.agent, "name", $$v)},expression:"agentService.agent.name"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.name")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.surname')),
                  }},[_c('label',{attrs:{"for":"surname"}},[_vm._v(" "+_vm._s(_vm.$tc("words.surname"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"id":"surname","name":_vm.$tc('words.surname')},model:{value:(_vm.agentService.agent.surname),callback:function ($$v) {_vm.$set(_vm.agentService.agent, "surname", $$v)},expression:"agentService.agent.surname"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.surname")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.miniGrid')),
                  }},[_c('label',[_vm._v(" "+_vm._s(_vm.$tc("words.miniGrid"))+" ")]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$tc('words.miniGrid'),"id":"miniGridName"},model:{value:(_vm.agentService.agent.miniGridId),callback:function ($$v) {_vm.$set(_vm.agentService.agent, "miniGridId", $$v)},expression:"agentService.agent.miniGridId"}},_vm._l((_vm.miniGrids),function(mg){return _c('md-option',{key:mg.id,attrs:{"value":mg.id}},[_vm._v(" "+_vm._s(mg.name)+" ")])}),1),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.miniGrid")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[[_c('vue-tel-input',{attrs:{"id":"phone","validCharactersOnly":true,"mode":"international","invalidMsg":"invalid phone number","disabledFetchingCountry":false,"disabledFormatting":false,"placeholder":"Enter a phone number","required":true,"preferredCountries":['TZ', 'CM', 'KE', 'NG', 'UG'],"autocomplete":"off","name":_vm.$tc('words.phone'),"enabledCountryCode":"true"},on:{"validate":_vm.validatePhone},model:{value:(_vm.agentService.agent.phone),callback:function ($$v) {_vm.$set(_vm.agentService.agent, "phone", $$v)},expression:"agentService.agent.phone"}}),(!_vm.phone.valid && _vm.firstStepClicked)?_c('span',{staticClass:"md-error",staticStyle:{"color":"red"}},[_vm._v(" invalid phone number ")]):_vm._e()]],2),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.email')),
                  }},[_c('label',{attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.$tc("words.email"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"id":"email","name":_vm.$tc('words.email')},model:{value:(_vm.agentService.agent.email),callback:function ($$v) {_vm.$set(_vm.agentService.agent, "email", $$v)},expression:"agentService.agent.email"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.email")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-datepicker',{attrs:{"name":"birthDate","md-immediately":"","md-close-on-blur":false},model:{value:(_vm.agentService.agent.birthday),callback:function ($$v) {_vm.$set(_vm.agentService.agent, "birthday", $$v)},expression:"agentService.agent.birthday"}},[_c('label',{attrs:{"for":"birth-date"}},[_vm._v(" "+_vm._s(_vm.$tc("words.birthday"))+" : ")])])],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.gender')),
                  }},[_c('label',{attrs:{"for":"gender"}},[_vm._v(_vm._s(_vm.$tc("words.gender"))+" :")]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$tc('words.gender'),"id":"gender"},model:{value:(_vm.agentService.agent.gender),callback:function ($$v) {_vm.$set(_vm.agentService.agent, "gender", $$v)},expression:"agentService.agent.gender"}},[(_vm.agentService.agent.gender == null)?_c('md-option',{attrs:{"disabled":""}},[_vm._v(" -- "+_vm._s(_vm.$tc("words.select"))+" -- ")]):_vm._e(),_c('md-option',{attrs:{"value":"male"}},[_vm._v(" "+_vm._s(_vm.$tc("words.male"))+" ")]),_c('md-option',{attrs:{"value":" female"}},[_vm._v(" "+_vm._s(_vm.$tc("words.female"))+" ")])],1),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.gender")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('phrases.commissionType')),
                  }},[_c('label',{attrs:{"for":"commission"}},[_vm._v(" "+_vm._s(_vm.$tc("phrases.commissionType"))+": ")]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$tc('phrases.commissionType'),"id":"commission"},model:{value:(_vm.agentService.agent.commissionTypeId),callback:function ($$v) {_vm.$set(_vm.agentService.agent, "commissionTypeId", $$v)},expression:"agentService.agent.commissionTypeId"}},_vm._l((_vm.agentCommissions),function(commission){return _c('md-option',{key:commission.id,attrs:{"value":commission.id}},[_vm._v(" "+_vm._s(commission.name)+" ")])}),1),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("phrases.commissionType")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.password')),
                  }},[_c('label',{attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$tc("words.password"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:15'),expression:"'required|min:3|max:15'"}],ref:"passwordRef",attrs:{"id":"password","name":_vm.$tc('words.password'),"type":"password"},model:{value:(_vm.agentService.agent.password),callback:function ($$v) {_vm.$set(_vm.agentService.agent, "password", $$v)},expression:"agentService.agent.password"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.password")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('phrases.confirmPassword')),
                  }},[_c('label',{attrs:{"for":"confirmPassword"}},[_vm._v(" "+_vm._s(_vm.$tc("phrases.confirmPassword"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:passwordRef'),expression:"'required|confirmed:passwordRef'"}],attrs:{"id":"confirmPassword","name":_vm.$tc('phrases.confirmPassword'),"type":"password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("phrases.confirmPassword")))+" ")])],1)],1)])])]),_c('md-progress-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"md-mode":"indeterminate"}})],1),_c('md-card-actions',[_c('md-button',{staticClass:"md-raised md-primary",attrs:{"role":"button","disabled":_vm.loading},on:{"click":_vm.saveAgent}},[_vm._v(" "+_vm._s(_vm.$tc("words.save"))+" ")]),_c('md-button',{staticClass:"md-raised",attrs:{"role":"button"},on:{"click":_vm.hide}},[_vm._v(" "+_vm._s(_vm.$tc("words.close"))+" ")])],1)],1)],1),_c('redirection-modal',{attrs:{"redirection-url":_vm.redirectionUrl,"imperative-item":_vm.imperativeItem,"dialog-active":_vm.redirectDialogActive}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }